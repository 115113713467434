<template>
  <div class="card-body elevation-5 d-flex mb-7">
    <v-icon class="icon-background ml-6 mt-6 mb-6 mr-6">
      {{ icon }}
    </v-icon>
    <div>
      <h2 class="mt-5 mb-2 ml-5 cor-titulo">{{ title }}</h2>
      <h3 class="mb-2 ml-5">Agendado para: {{ (date == null) ? '--/--/----' : dataFormatada(date) }}</h3>
      <h3 :class="['mb-5', 'ml-5', (codStatus == 2)?'cor-aprovada':(codStatus == 1)?'cor-analise':'']">{{ serviceStatusName }}</h3>
    </div>
  </div>
</template>

<script>
import moment from "moment";

export default {
  props: {
    serviceStatusName: {
      default: "--"
    },
    title: {
      default: '----'
    },
    date: {
      default: "--/--/----"
    },
    icon: {
      default: "mdi-handshake"
    },
    codStatus: {
      default: 0
    }
  },
  name: 'ListCardItem',
  data: () => ({
    adicionar: false,
  }),
  methods: {
    dataFormatada(data) {9
      return moment(data).format('DD/MM/YYYY HH:MM')
    },
  }
}
</script>

<style>
.card-body {
  border-radius: 10px;
}

.cor-aprovada {
  color: #056644;
}

.cor-analise {
  color: #FFD73F;
}

.icon-background {
  background-color: #EA5C2D;
  border-radius: 50%;
  padding: 10px;
}

.cor-titulo {
  color: #EA5C2D;
}
</style>
